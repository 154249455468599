import update from 'immutability-helper'

export const extendWithUpdateArrayByKey = () =>
  update.extend('$updateArrayByKey', ({ key, value, func }, original) => {
    const modified = original.slice()
    const index = modified.findIndex(el => el[key] === value)
    if (index > -1) {
      const object = modified[index]
      modified[index] = func(object)
    }
    return modified
  })
