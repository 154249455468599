import { feature, toriiIdOrg, demoIdOrg } from '../utils'

const config = {
  segment: 'n2wZhQK7CpKmNPfYnh8WbUxjTbhWJGJr',
  proxyApiRequests: true,
  webBaseUrl: `https://${process.env.REACT_APP_ROOT_DOMAIN || 'toriihq.com'}`,
  apiBaseUrl: `https://${process.env.REACT_APP_API_DOMAIN || 'api.toriihq.com'}`,
  appBaseUrl: `https://${process.env.REACT_APP_TORII_DOMAIN_APP || 'app.toriihq.com'}`,
  catalogBaseUrl: `https://${process.env.REACT_APP_TORII_DOMAIN_CATALOG || 'catalog.toriihq.com'}`,
  features: {
    apiAccess: {
      isEnabled: feature.enabledForOrgs([toriiIdOrg, demoIdOrg, '15044', '15056'])
    },
    licensesPage: {
      isEnabled: feature.enabled
    }
  },
  azure: {
    clientId: '162a033b-7435-4ba7-ba7b-b4db768d34c6'
  }
}

export default config
